<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div
          class="shape shape-style-3 shape-default shape-skew"
          id="homepage-hero"
        ></div>
        <div class="container shape-container d-flex" style="padding-top: 15rem;">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-6 text-center">
                <h1 class="display-3 text-white">Welcome to the <span>HPC @ Uni.lu Platform</span></h1>
              </div>
              <div class="col-lg-3"></div>
            </div>
            <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-10">
                <b-carousel
                  id="homepage-carousel"
                  :interval="10000"
                  :no-touch="false"
                  controls
                  indicators
                  background="linear-gradient(to left, rgba(92,92,92,0) 0%, rgba(92,92,92,1) 29%, rgba(92,92,92,1) 79%, rgba(92,92,92,0) 100%)"
                  img-height="480px"
                  img-width="1200px"
                >
                  <!-- Text slides with image -->
                  <b-carousel-slide
                    img-src="assets/img/carousel/iris-racks-corridor_unilu.jpg"
                    caption="UL CDC (Centre de Calcul)"
                    text="UL Computing Center, hosting State-of-the-art High Performance Computing clusters."
                  ></b-carousel-slide>
                  <b-carousel-slide
                    img-src="assets/img/carousel/ministry-of-economy.jpeg"
                    caption-html="<a href='/blog/2022-09-26-ministry-of-economy' target='_blank'>Ministry of Economy agreement with UL about HPC/HPDA/AI</a>"
                    text="The Ministry of Economy signs agreement to support teaching and research in HPC/HPDA/AI."
                  ></b-carousel-slide>
                  <b-carousel-slide
                    img-src="assets/img/carousel/cpu.png"
                    caption-html="<a href='/blog/2022-09-07-amazon' target='_blank'>Amazon AWS and ULHPC</a>"
                    text="University of Luxembourg to use AWS Processors in HPC Research."
                  ></b-carousel-slide>
                  <b-carousel-slide
                    img-src="assets/img/carousel/2022-pcog-video-alt-image.png"
                    caption-html="<a href='https://pcog.uni.lu/#footer' target='_blank'>HPC & PCOG Video</a>"
                    text="Discover the HPC & PCOG presentation video on PCOG's website."
                  ></b-carousel-slide>
                  <b-carousel-slide
                    img-src="assets/img/carousel/ulhpc-school-november-2021.jpg"
                    caption-html="<a href='/education/hpcschool/2021-november' target='_blank'>UL HPC School 2021</a>"
                    text="HPC School 2021 @uni_lu is finished! Thank you to all friend and colleagues for this new success!"
                  ></b-carousel-slide>
                  <b-carousel-slide
                    img-src="assets/img/carousel/aion-front.jpg"
                    caption-html="<a href='/blog/2021-10-03-official-production-release-of-the-aion-supercomputer' target='_blank'>Aion Production Release</a>"
                    text="The Aion supercomputer is now released after the end of beta testing phase (started on July, 30th 2021)"
                  ></b-carousel-slide>
                  <b-carousel-slide
                    img-src="/download/documents/ULHPC-Annual-report-2020.png"
                    caption-html="<a href='/download/documents/ULHPC-Annual-report-2020.pdf' target='_blank'>Activity Report 2020</a>"
                    text="Read what ULHPC achieved in 2020."
                  ></b-carousel-slide>
                  <b-carousel-slide
                    img-src="assets/img/carousel/hpc-docs-frontpage.png"
                    caption-html="<a href='https://hpc-docs.uni.lu' target='_blank'>Technical Documentation 2021</a>"
                    text="Discover our brand new technical documentation."
                  ></b-carousel-slide>
                  <b-carousel-slide
                    img-src="assets/img/carousel/2020-ULHPC-overview.png"
                    caption-html="<a href='/download/slides/2020-ULHPC-overview.pdf' target='_blank'>Uni.lu HPC Overview (2020)</a>"
                    text="Status Update of ULHPC: new cluster, new people, new challenges!"
                  ></b-carousel-slide>
                </b-carousel>
              </div>
              <div class="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <section class="section section-lg pt-lg-0 mt--200">
      <div class="container">
        <div class="row justify-content-center align-items-start">
          <div class="col-lg-7" id="homepage-cards">
            <div class="row row-grid">
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h6 class="text-primary text-sc"><i class="far fa-newspaper"></i> Latest News</h6>
                  <p class="mt-3">
                    Get the latest news / advertisements linked to the UL HPC
                    platform in this page.
                  </p>
                  <ul>
                    <li v-for="blogPost in this.getLastNBlogPosts(6)" :key="blogPost.slug">
                      <router-link :to="{ name: 'blog', params: { slug: blogPost.slug }}" >
                        {{ blogPost.title }}
                      </router-link>
                    </li>
                  </ul>
                </card>
              </div>
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h6 class="text-primary text-sc"><i class="fab fa-github"></i> Github Repos</h6>
                  <p class="mt-3">
                    Discover latests reference slides which present ULHPC
                    systems.
                  </p>
                  <base-button
                    tag="a"
                    href="https://github.com/ULHPC/tutorials"
                    target="_blank"
                    type="primary"
                    class="mt-4"
                  >
                    ULHPC/tutorials
                  </base-button>
                  <base-button
                    tag="a"
                    href="https://github.com/ULHPC/tools"
                    target="_blank"
                    type="primary"
                    class="mt-4"
                  >
                    ULHPC/tools
                  </base-button>
                  <base-button
                    tag="a"
                    href="https://github.com/ULHPC/documents"
                    target="_blank"
                    type="primary"
                    class="mt-4"
                  >
                    ULHPC/documents
                  </base-button>
                  <base-button
                    tag="a"
                    href="https://github.com/ULHPC/dotfiles"
                    target="_blank"
                    type="primary"
                    class="mt-4"
                  >
                    ULHPC/dotfiles
                  </base-button>
                  <base-button
                    tag="a"
                    href="https://github.com/ULHPC/"
                    target="_blank"
                    type="secondary"
                    class="mt-4"
                  >
                    More repos ...
                  </base-button>
                </card>
              </div>
            </div>

            <div class="row row-grid">
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h6 class="text-primary text-sc">
                    <i class="fas fa-book"></i> Technical Docs
                  </h6>
                  <p class="mt-3">
                    The brand new ULHPC Technical documentation website is
                    available! Please make sure you read it carefully.
                  </p>
                  <router-link :to="{ name: 'users-documentation' }">
                    <base-button tag="div" type="primary" class="mt-4">
                      ULHPC Technical doc
                    </base-button>
                  </router-link>
                </card>
              </div>

              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h6 class="text-primary text-sc"><i class="fas fa-cogs"></i> Featured Systems</h6>
                  <p class="mt-3">
                    Discover latests reference slides which present ULHPC
                    systems.
                  </p>
                  <a href="/download/slides/2020-ULHPC-overview.pdf" target="_blank">
                    <base-button tag="div" type="primary" class="mt-4">
                      See reference slides
                    </base-button>
                  </a>
                </card>
              </div>
            </div>

            <div class="row row-grid">
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h6 class="text-primary text-sc"><i class="fas fa-scroll"></i> Publications</h6>
                  <p class="mt-3">
                    Check the collection of publications related to the UL HPC
                    platform or made by the researchers thanks to it.
                  </p>
                  <router-link :to="{ name: 'about-publications' }">
                    <base-button tag="div" type="primary" class="mt-4">
                      Learn more
                    </base-button>
                  </router-link>
                </card>
              </div>

              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h6 class="text-primary text-sc"><i class="fas fa-users-cog"></i> Management Team</h6>
                  <p class="mt-3">
                    Discover who’s behind the platform and ensure that it is
                    running correctly.
                  </p>
                  <router-link :to="{ name: 'about-team' }">
                    <base-button tag="div" type="primary" class="mt-4">
                      Meet the team
                    </base-button>
                  </router-link>
                </card>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue/esm/components/button/button";
import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BSpinner } from "bootstrap-vue/esm/components/spinner/spinner";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";
import { Timeline } from "vue-tweet-embed";
import blogEntriesList from "../contents/blog/index.json";

export default {
  components: {
    BButton,
    BCarousel,
    BSpinner,
    BCarouselSlide,
    Timeline,
  },
  metaInfo: {
    title: 'Homepage'
  },
  mounted() {
    this.cropTweets();
  },

  methods: {
    cropTweets() {
      document
        .getElementById("homepage-tweets")
        .setAttribute(
          "style",
          "height:" +
            document.getElementById("homepage-cards").clientHeight +
            "px"
        );
    },
    getLastNBlogPosts(n) {
      let lastNBlogPosts = [];
      for (const [yearKey, yearObject] of Object.entries(blogEntriesList.posts)) {
        for (const [key, blogObject] of Object.entries(yearObject[Object.keys(yearObject)[0]])) {
          lastNBlogPosts.push(blogObject)
          if(lastNBlogPosts.length >= n){
            return lastNBlogPosts;
          }
        }
      }
      return lastNBlogPosts;
    }
  },
};
</script>
<style>
#homepage-hero::after {
  content: "";
  background: url("/assets/img/common/hero_fili_retouched.jpg") no-repeat;
  background-position: 50% 60%;
  background-size: cover;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

#homepage-tweets {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#homepage-tweets::-webkit-scrollbar {
  display: none;
}
</style>
